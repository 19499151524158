//See https://bootswatch.com/darkly/
@import "~bootswatch/dist/darkly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

@import "~bootstrap-icons/font/bootstrap-icons.css";

.card-body {
  padding: 0 !important;
}

.card-text {
  padding: 1rem;
}

.table-icon {
  font-size: 35px;
}

.table-content {
  font-size: 20px;
}

/* Set black background color, white text and some padding */
footer {
  background-color: #555;
  color: white;
  padding: 15px;
}

/* On small screens, set height to 'auto' for sidenav and grid */
@media screen and (max-width: 767px) {
  .sidenav {
   height: auto;
   padding: 25px 5px 15px 15px;
  }

  .row.content {
   height: auto;
   padding-top: 10px;
  }
}

div.container-fluid {
  width: 95% !important;
}


div.mapContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

div.mapCenter {
  position: absolute;
  z-index: 10;
  color: red;
  top: 49%;
  left: 50%;
  pointer-events: none;
}

.custom-hover:hover {
  color: #2c81ba;
}

.version-infos {
  position: absolute;
  right: 50px;
  top: 15px;
  z-index: 1000;
}

.accordion-button {
  background-color: #444 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  font-size: 1.25rem !important;
}


.accordion-button:not(.collapsed) {
  background-color: #444 !important;
  color: white !important;
}

.accordion-body {
  background-color: #303030;
}